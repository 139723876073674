import React, { lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router';
import Loader from 'components/Loader';

const HomePage = lazy(() => import('./pages/home'));
const ProjectPage = lazy(() => import('./pages/project'));
const ProjectConfigurationPage = lazy(() => import('./pages/projectConfiguration'));
const Login = lazy(() => import('./pages/login'));
const LoginCallback = lazy(() => import('./pages/loginCallback'));
const AdminPage = lazy(() => import('./pages/admin'));
const CategoryPage = lazy(() => import('./pages/category'));
const ProjectDisplayPage = lazy(() => import('./pages/projectDisplay'));
const IndicatorsPage = lazy(() => import('./pages/indicators'));
const CategoryIndicatorPage = lazy(() => import('./pages/category-indicators'));

const routes = () => (
  <Suspense fallback={<Loader />}>
    <Switch>
      <Route exact path="/" component={HomePage} />
      <Route path="/project/:projectId/configuration" component={ProjectConfigurationPage} />
      <Route path="/project/:projectId/display" component={ProjectDisplayPage} />
      <Route path="/project/:projectId" component={ProjectPage} />
      <Route path="/administration" component={AdminPage} />
      <Route path="/indicators" component={IndicatorsPage} />
      <Route path="/category-indicators/:categoryId?" component={CategoryIndicatorPage} />
      <Route path="/login" component={Login} />
      <Route path="/callback" component={LoginCallback} />
      <Route path="/category/:categoryId?" component={CategoryPage} />
      <Route render={() => <Redirect to="/" />} />
    </Switch>
  </Suspense>
);

export default routes;
