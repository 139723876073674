import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { LoaderContainer } from './FullPageLoader.style';

class FullPageLoader extends React.Component {
  render = () => (
    <LoaderContainer>
      <CircularProgress style={{ color: 'white' }} />
    </LoaderContainer>
  );
}

export default FullPageLoader;
