import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { getSpacing, colorPalette } from 'stylesheet';

const MENU_WIDTH = getSpacing(13);

export const MenuContainer = styled.aside`
  width: ${MENU_WIDTH};
  display: flex;
  flex-direction: column;
  background-color: ${colorPalette.greenLight};

  @media print {
    display: none;
  }
`;

export const MenuLogoContainer = styled.div`
  width: ${MENU_WIDTH};
  height: ${MENU_WIDTH};
  background: ${colorPalette.green};
`;

export const MenuNav = styled.nav`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: ${getSpacing(4)} 0;
`;

export const TopMenuItems = styled.div`
  & > * {
    margin: ${getSpacing(4)} auto;
    display: block;
  }
`;

export const MenuItemLink = styled(Link)`
  padding: ${getSpacing(2)};
  border-radius: ${getSpacing(1)};

  &:hover {
    background-color: ${colorPalette.green};
    box-shadow: 0 1px 6px 0 rgba(38, 221, 173, 0.31);
  }
`;

export const MenuItemIcon = styled.div`
  display: block;
  margin: auto;
  color: ${colorPalette.white};
`;
