import React, { ReactNode } from 'react';
import { MenuItemLink, MenuItemIcon } from './Menu.style';

interface Props {
  onClick?: () => void;
  to: string;
  icon: ReactNode;
}

const MenuItem: React.FunctionComponent<Props> = ({ to, icon }) => (
  <MenuItemLink to={to}>
    <MenuItemIcon>{icon}</MenuItemIcon>
  </MenuItemLink>
);

export default MenuItem;
