import { css } from 'styled-components';

/**
 * App spacing measurement convention
 * Use the getSpacing function below to compute padding and margin
 */
const SPACING_UNIT = 5;
const MEASUREMENT_UNIT = 'px';

/**
 * Do not use directly the colorPalette in your components
 * Create an entry in the colorUsage below instead
 */
export const colorPalette = {
  greyLightest: '#fafafa',
  greyLight: 'rgba(233, 239, 242, 1)',
  greyDark: 'rgba(68, 73, 76, 1)',
  grey: '#808080',
  amberLight: '#FFD54F',
  amber: '#FFC107',
  amberDark: '#FF8F00',
  white: '#FFFFFF',
  red: '#FF1C1C',
  redLight: 'rgba(255, 157, 183, 1)',
  redLightest: 'rgba(255, 153, 153, 1)',
  blue: '#6C7AE0',
  green: 'rgba(30, 186, 146, 1)',
  greenLight: 'rgba(38, 221, 173, 1)',
  greenLightest: 'rgba(130, 202, 157, 1)',
  blackTransparent: 'rgba(0, 0, 0, 0.24)',
  blueLight: 'rgba(243, 250, 255, 1)',
  blueDark: '#005296',
};

export const colorUsage = {
  chartOk: colorPalette.greenLightest,
  chartKo: colorPalette.redLightest,
  chartStandard: colorPalette.red,
};

export const fontFamily = {
  main: `'Geomanist', sans-serif`,
  code: 'Monospace',
};

// We are in the stylesheet, we can define variables here
// stylelint-disable
export const typography = {
  main: css`
    font-family: ${fontFamily.main};
  `,
  h3: css`
    font-family: ${fontFamily.main};
    font-size: 16px;
    line-height: 1;
    font-weight: 500;
  `,
  small: css`
    font-family: ${fontFamily.main};
    font-size: 12px;
    line-height: 1;
    font-weight: 600;
  `,
};

export const fontSize = {
  XXLarge: '40px',
  XLarge: '28px',
  large: '20px',
  medium: '16px',
  small: '14px',
  XSmall: '12px',
};

export const fontWeight = {
  bold: '700',
  normal: '400',
  light: '300',
};

export const lineHeight = {
  large: '36px',
  medium: '24px',
  small: '12px',
};

export const borderRadius = {
  medium: '4px',
  large: '10px',
};

export const breakpoints = {
  screen: '600px',
};

export const getSpacing = (multiplier: number): string =>
  `${multiplier * SPACING_UNIT}${MEASUREMENT_UNIT}`;
