import React from 'react';

import { ConnectedRouter } from 'connected-react-router';
import { History } from 'history';
import { Provider } from 'react-redux';
import { Route } from 'react-router';
import { Store } from 'redux';
import { Persistor } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { IntlProvider } from 'react-intl';
import { flattenMessages } from 'services/i18n/intl';
import enMessages from 'translations/en.json';
import frMessages from 'translations/fr.json';

import AppCrashFallback from 'components/AppCrashFallback';
import ErrorBoundary from 'components/ErrorBoundary';
import Root from 'components/Root';
import AuthGate from 'components/AuthGate';
import FullPageLoader from 'components/FullPageLoader';

import Routes from './routes';

import 'assets/fonts.scss';

interface Props {
  history: History;
  persistor: Persistor;
  store: Store;
}

const RootComponentWithRoutes: React.FunctionComponent = () => (
  <AuthGate loading={<FullPageLoader />}>
    <Root>
      <Routes />
    </Root>
  </AuthGate>
);

const locales = {
  fr: flattenMessages(frMessages),
  en: flattenMessages(enMessages),
};

const App: React.FunctionComponent<Props> = ({ history, persistor, store }) => (
  <ErrorBoundary FallbackComponent={AppCrashFallback}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <IntlProvider locale="fr" messages={locales.fr}>
          <ConnectedRouter history={history}>
            <Route path="/" component={RootComponentWithRoutes} />
          </ConnectedRouter>
        </IntlProvider>
      </PersistGate>
    </Provider>
  </ErrorBoundary>
);

export default App;
