import { getType } from 'typesafe-actions';
import { AnyAction } from 'redux';
import { resetAuthState, saveToken, AuthAction } from './actions';

export type AuthState = Readonly<{
  token: string;
  email: string;
  admin: boolean;
}>;

const initialState: AuthState = {
  token: '',
  email: '',
  admin: false,
};

const authReducer = (state: AuthState = initialState, action: AnyAction) => {
  const typedAction = action as AuthAction;
  switch (typedAction.type) {
    case getType(saveToken):
      return {
        ...state,
        token: typedAction.payload.token,
      };
    case getType(resetAuthState):
      return initialState;
    default:
      return state;
  }
};

export default authReducer;
