import React from 'react';
import Logo from './Loader.style';
import { ReactComponent as LoaderIcon } from './static/loader.svg';

const Loader = () => {
  return (
    <Logo>
      <LoaderIcon />
    </Logo>
  );
};

export default Loader;
