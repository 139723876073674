import auth0, { Auth0DecodedHash, Auth0ParseHashError } from 'auth0-js';

import AUTH0_CONFIG from './config';

const webAuth = new auth0.WebAuth(AUTH0_CONFIG);

const login = () => {
  localStorage.setItem('routeBeforeLogin', window.location.pathname);
  return webAuth.authorize();
};

const parseLoginCallbackUrl = (
  handleCallback: (err: Auth0ParseHashError | null, authResult: Auth0DecodedHash | null) => void,
) => webAuth.parseHash({}, handleCallback);

export default { login, parseLoginCallbackUrl };
