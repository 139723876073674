import { reducer as formReducer } from 'redux-form';
import { adminReducer as admin, i18nReducer } from 'react-admin';
import i18nProvider from './i18nProvider';
const DEFAULT_LOCALE = 'fr';

export default {
  admin,
  i18n: i18nReducer(DEFAULT_LOCALE, i18nProvider(DEFAULT_LOCALE)),
  form: formReducer,
};
