import React from 'react';
import Menu from './Menu';
import { RootWrapper, MainWrapper } from './Root.style';

const Root: React.FunctionComponent = ({ children }) => (
  <RootWrapper>
    <Menu />
    <MainWrapper>{children}</MainWrapper>
  </RootWrapper>
);

export default Root;
