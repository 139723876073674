const AUTH0_URL = `${process.env.REACT_APP_AUTH0_DOMAIN}`;

const CLIENT_ID = `${process.env.REACT_APP_AUTH0_CLIENT_ID}`;
const CLIENT_SECRET = `${process.env.REACT_APP_AUTH0_CLIENT_SECRET}`;
const CALLBACK_URL = `${process.env.REACT_APP_AUTH0_CALLBACK_URL}`;

const AUTH0_CONFIG = {
  domain: AUTH0_URL,
  clientID: CLIENT_ID,
  clientSecret: CLIENT_SECRET,
  redirectUri: CALLBACK_URL,
  audience: `https://${AUTH0_URL}/userinfo`,
  responseType: 'token id_token',
  scope: 'openid profile email',
};

export default AUTH0_CONFIG;
