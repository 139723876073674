import { Reducer, AnyAction } from 'redux';
import pickBy from 'lodash/pickBy';
import { createStandardAction, getType, ActionType } from 'typesafe-actions';

import { VP, Techno, Project, Category, Standard, ProjectStandard } from 'services/types';

type Dict<T> = Readonly<Record<string, T>>;

export type EntityCatalog = Readonly<{
  vps: Dict<VP>;
  technos: Dict<Techno>;
  projects: Dict<Project>;
  categories: Dict<Category>;
  standards: Dict<Standard>;
  projectStandards: Dict<ProjectStandard>;
}>;

export type EntityType<T extends keyof EntityCatalog> = EntityCatalog[T] extends Dict<infer U>
  ? U
  : never;

const initialState: EntityCatalog = {
  vps: {},
  technos: {},
  projects: {},
  categories: {},
  standards: {},
  projectStandards: {},
};

export const addAnyEntities = createStandardAction('ADD_ENTITIES')<Partial<EntityCatalog>>();

export const removeAnyEntities = createStandardAction('REMOVE_ENTITIES')<
  { [type in keyof EntityCatalog]?: string[] }
>();

export type EntitiesActions = ActionType<typeof addAnyEntities | typeof removeAnyEntities>;

export const reducer: Reducer<EntityCatalog, AnyAction> = (state = initialState, action) => {
  const typedAction = action as EntitiesActions;
  switch (typedAction.type) {
    case getType(addAnyEntities):
      return Object.entries(typedAction.payload).reduce(
        (tempState, [type, newEntities]) => ({
          ...tempState,
          [type]: {
            ...tempState[type as keyof EntityCatalog],
            ...newEntities,
          },
        }),
        state,
      );

    case getType(removeAnyEntities):
      return Object.entries(typedAction.payload).reduce(
        (tempState, [type, toRemove]) => ({
          ...tempState,
          [type]: pickBy(
            tempState[type as keyof EntityCatalog],
            (_, id) => !toRemove || !toRemove.includes(id),
          ),
        }),
        state,
      );

    default:
      return state;
  }
};
