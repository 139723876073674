import React from 'react';
import DashboardIcon from '@material-ui/icons/Dashboard';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SearchIcon from '@material-ui/icons/Search';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import TimelineIcon from '@material-ui/icons/Timeline';
import { MenuContainer, MenuLogoContainer, MenuNav, TopMenuItems } from './Menu.style';
import MenuItem from './MenuItem';
import { useAuth } from 'redux/Auth/hooks';

const Menu: React.FunctionComponent = () => {
  const { logout } = useAuth();
  return (
    <MenuContainer>
      {/* Put logo inside here */}
      <MenuLogoContainer />
      <MenuNav>
        <TopMenuItems>
          <MenuItem to="/" icon={<DashboardIcon />} />
          <MenuItem to="/category" icon={<SearchIcon />} />
          <MenuItem to="/indicators" icon={<EqualizerIcon />} />
          <MenuItem to="/category-indicators" icon={<TimelineIcon />} />
          <MenuItem to="/administration" icon={<SettingsApplicationsIcon />} />
        </TopMenuItems>
        <MenuItem to="" icon={<ExitToAppIcon />} onClick={logout} />
      </MenuNav>
    </MenuContainer>
  );
};

export default Menu;
