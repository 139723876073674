import mapKeys from 'lodash/mapKeys';

import { EntityCatalog, EntityType, addAnyEntities, removeAnyEntities } from './module';

export const normalize = <T extends keyof EntityCatalog>(
  type: T,
  entities: (EntityType<T>)[],
): Partial<EntityCatalog> => ({
  [type]: mapKeys(entities, 'id'),
});

export const addEntities = <T extends keyof EntityCatalog>(type: T, entities: (EntityType<T>)[]) =>
  addAnyEntities(normalize(type, entities));

export const removeEntities = <T extends keyof EntityCatalog>(type: T, ids: string[]) =>
  removeAnyEntities({ [type]: ids });
