import styled from 'styled-components';

export const LoaderContainer = styled.div`
  margin: auto;
  width: 100%;
  height: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
`;
