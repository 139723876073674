import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useAuth } from 'redux/Auth/hooks';

interface AuthGateProps extends RouteComponentProps {
  loading: React.ReactNode;
}

export const AuthGate: React.FunctionComponent<AuthGateProps> = ({
  loading,
  location,
  children,
}) => {
  const { isAuthenticated, check } = useAuth();

  useEffect(
    () => {
      if (location.pathname !== '/callback') {
        check();
      }
    },
    [check, location.pathname],
  );

  return <>{isAuthenticated || location.pathname === '/callback' ? children : loading} </>;
};

export default withRouter(AuthGate);
