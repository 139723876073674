import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import JwtDecode from 'jwt-decode';
import { Auth0DecodedHash } from 'auth0-js';
import auth0Methods from 'services/auth0/methods';
import { RootState } from 'redux/types';
import { saveToken, resetAuthState } from './actions';

interface DecodedToken {
  exp: number;
}

export const useAuth = () => {
  const dispatch = useDispatch();

  const isAuthenticated = useSelector((state: RootState) => state.auth.token !== '');

  const check = useCallback(
    () => {
      const token = sessionStorage.getItem('accessToken');
      if (!token) {
        auth0Methods.login();
        return;
      }
      const decodedToken: DecodedToken = JwtDecode(token);
      const { exp } = decodedToken;
      if (Date.now() >= exp * 1000) {
        return auth0Methods.login();
      }
      dispatch(saveToken({ token }));
    },
    [dispatch],
  );

  const login = useCallback(
    (result: Auth0DecodedHash) => {
      sessionStorage.setItem('accessToken', result.idToken || '');
      check();
    },
    [check],
  );

  const logout = useCallback(
    () => {
      sessionStorage.removeItem('accessToken');
      dispatch(resetAuthState());
      auth0Methods.login();
    },
    [dispatch],
  );

  return { isAuthenticated, check, login, logout };
};
