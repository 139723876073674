import { ActionType, createStandardAction } from 'typesafe-actions';

export const saveToken = createStandardAction('SAVE_TOKEN')<{
  token: string;
}>();

export const resetAuthState = createStandardAction('RESET_AUTH_STATE')();

export type AuthAction = ActionType<typeof resetAuthState | typeof saveToken>;

export default {
  saveToken,
  resetAuthState,
};
