import styled from 'styled-components';
import { colorPalette, getSpacing, typography } from 'stylesheet';

export const RootWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  ${typography.main}
  color: ${colorPalette.greyDark};
`;

export const MainWrapper = styled.div`
  flex: 1;
  padding: ${getSpacing(5)};
  overflow-y: auto;
  background: ${colorPalette.greyLightest};

  @media print {
    padding: 0;
    background: white;
    overflow-y: visible;
  }
`;
